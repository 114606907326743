module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Horizon Fargo Real Estate","short_name":"Horizon Fargo","start_url":"/","background_color":"#237e5f","theme_color":"#237e5f","display":"minimal-ui","icon":"src/images/favicon.svg"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"horizon-fargo","pages":[{"type":"Blog_post","match":"/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/news.js"}],"sharpKeys":[{},"logo"],"omitPrismicScript":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
