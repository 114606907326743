/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/global.scss';
import 'aos/dist/aos.css';

import { registerLinkResolver } from 'gatsby-source-prismic-graphql';
import linkResolver from './src/util/prismicLinkResolver';

registerLinkResolver(linkResolver);
